import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Contact from "./elements/Contact";
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import HomePage from './home/HomePage';
import WebDevelopment from './pages/WebDevelopment'
import ECommerce from './pages/ECommerce'
import Crm from './pages/Crm'
import Erp from './pages/Erp'
import SeoDigitalMarketing from './pages/SeoDigitalMarketing'
import ScrollToTop from './component/common/ScrollToTop'

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <ScrollToTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePage}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/web-development`} component={WebDevelopment}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/e-commerce`} component={ECommerce}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/crm`} component={Crm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/erp`} component={Erp}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/seo-digital-marketing`} component={SeoDigitalMarketing}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route component={error404}/>
                    </Switch>
                </ScrollToTop>
            </BrowserRouter>
        )
    }
}
ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();