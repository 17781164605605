import React, { Component } from "react";
import validator from 'validator';
import { Link } from "react-router-dom";

class ContactTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            rnNameError: false,
            rnEmailRequiredError: false,
            rnEmailError: false,
            rnSubjectError: false,
            rnMessageError: false,
            formStatus: null,
        };
    }

    handleSubmit = async (event) => {
        if (!event.target.checkValidity()) {
            event.target.classList.add('was-validated');
            event.preventDefault();
            event.stopPropagation();
        }


        // validate fields and sent to mailchimp

        let rnNameError = validator.isEmpty(this.state.rnName);
        let rnEmailRequiredError = validator.isEmpty(this.state.rnEmail);
        let rnEmailError = !validator.isEmail(this.state.rnEmail);
        let rnSubjectError = validator.isEmpty(this.state.rnSubject);
        let rnMessageError = validator.isEmpty(this.state.rnMessage);

        if (!rnNameError && !rnEmailError && !rnSubjectError && !rnMessageError && !rnEmailRequiredError) {
            let urlData = `?name=${this.state.rnName}&email=${this.state.rnEmail}&subject=${this.state.rnSubject}&message=${this.state.rnMessage}`;
            fetch('https://leetodigitalagency.com/contact-us/index.php'+urlData, {
                method: 'GET', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    Accept: 'application/json',
                },
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        console.log(json);
                        this.setState({formStatus: json.status})
                    });
                    }
                
            });
        }
        this.setState({
            rnNameError: rnNameError,
            rnEmailError: rnEmailError,
            rnSubjectError: rnSubjectError,
            rnMessageError: rnMessageError,
            rnEmailRequiredError: rnEmailRequiredError,
        })
        event.preventDefault();
    }

    closeModal = () => {
        this.setState({ formStatus: null })
        
    }
    
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us.</h2>
                                <p className="description">Write a message to us and someone from our team will get in touch with you.</p>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            * Name is required.
                                        </div>
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            * Email is required.
                                        </div>
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            * Subject is required.
                                        </div>
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            * Message is required.
                                        </div>
                                    </label>
                                    {/* <ReCAPTCHA sitekey="6LdrCC8aAAAAADz0SmXiybNV6YCiUounSYxgXInV" action='contact_us' verifyCallback={alert} /> */}
                                    {/* <ReCAPTCHA ref={recaptchaRef} sitekey="6LfREy8aAAAAAJErldx3J4RqC3PKDQPGP1ravIwt" size="invisible" /> */}
                                    {/* <GoogleReCaptchaProvider reCaptchaKey="6LdrCC8aAAAAADz0SmXiybNV6YCiUounSYxgXInV">
                                        <GoogleReCaptcha onVerify={(reCaptcha) => {this.setState({reCaptcha: reCaptcha});}} />
                                    </GoogleReCaptchaProvider>, */}
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe" >Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="mb_md--30 mb_sm--30 text-center">
                                <img src="/assets/images/logo/contact-logo.webp" alt="Leeto Digital Agency"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{display: this.state.formStatus === 'success' ? "block" : "none"}} className="modal fade show" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            {this.state.formStatus === 'success' && <h5 className="modal-title">Success</h5>}
                            {this.state.formStatus === 'error' && <h5 className="modal-title">Error</h5>}
                                <Link to="/" onClick={this.closeModal}>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </Link>
                        </div>
                        <div className="modal-body">
                            {this.state.formStatus === 'success' && <p>We will be in touch with you shortly!</p>}
                            {this.state.formStatus === 'error' && <p>Something went wrong! Please send us an email to: <a href="mailto:contact@leetodigitalagency.com">contact@leetodigitalagency.com</a></p>}
                        </div>
                        <div className="modal-footer">
                            {this.state.formStatus === 'success' &&
                                <Link to="/" onClick={this.closeModal}>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </Link>
                            }
                                
                            {this.state.formStatus === 'error' &&
                                <button onClick={this.closeModal} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            }
                        </div>
                        </div>
                    </div>
                </div>
                {this.state.formStatus === 'success' && <div className="modal-backdrop fade show"></div>}
            </div>
        )
    }
}
export default ContactTwo;