import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class ECommerce extends Component{

    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='E-Commerce Development' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image-web-development"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h1 className="title theme-gradient">Team Up with Leeto and Start Selling Online</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <p>We excel at providing solutions for your e-commerce business at every stage; from planning to launching to expanding to evolving. We design, build, and maintain e-commerce solutions using Magento, Prestashop, WooCommerce and fully customized e-commerce solutions.</p>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/magento.png" alt="Magento Development"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2>Magento E-Commerce Development</h2>
                                                    <p>Magento offers eCommerce platform solutions that are flexible, robust, scalable and intuitive to easily grow your eCommerce business. To discover the limitless opportunities that Magento can extend, a deeper understanding of Magento eCommerce development is required. Our Magento eCommerce store development services can help you to leverage the advanced features of Magento to your advantage. <br/> Our competence over Magento helps us customize every solution we offer to our clients. </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12 order-lg-1 order-md-2">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2>PrestaShop E-Commerce Development</h2>
                                                    <p>PrestaShop is an open-source e-Commerce solution that comes under open source license. It is robust, free and feature-rich e-Commerce platform which helps to build e-Commerce website for online Business merchants. <br/> Our PrestaShop development service ranges from responsive theme design and development to custom module development and multi-store setup etc. We have a team of highly skilled developers with ample experience in the latest e-Commerce development technologies for small, medium and large-sized online businesses. We are also skilled in developing required modules and plugins to make user-friendly e-commerce application for global users.</p>
                                                    <br />    
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-lg-2 order-md-1">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/prestashop.png" alt="PrestaShop Development"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/woocommerce.png" alt="WooCommerce Development"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2>WooCommerce Development</h2>
                                                    <p>WooCommerce powers over 15.2 million websites and more than 37% of online stores across the world. A smart WooCommerce development plan allows you to create a robust e-commerce business at a low cost. With this plugin, you can develop your own online store or convert your existing WordPress website into a sales-driven platform. This involves using a wide range of eShop facilities within the WooCommerce framework including theme customization, plugin customization, payment gateway integration, and more.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default ECommerce;