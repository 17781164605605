import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class WebDevelopment extends Component{

    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Web Development' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image-web-development"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h1 className="title theme-gradient">Team Up with Leeto for Your Web Development Needs</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <p>Leeto brings bold ideas to life through strategy, design and development. We build rich, dynamic experiences that exceed user expectations, drive conversions, engage customers and fuel growth.</p>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/web-development.webp" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Tailor-Made Web Development Services</h4>
                                                    <p>At Leeto, we believe that there is no one-size-fits-all approach to web development. We use cutting-edge technologies to deploy custom solutions for front-end and back-end needs. We make sure that your site meets your unique specifications, in all aspects. Our services can help your business grow, all while staying within your budget.</p>
                                                    <br />
                                                    <br/>
                                                    <h4>Driving Clicks and Conversions</h4>
                                                    <p>Together, we can build rich, seamless experiences that increase brand awareness and drive sales. Leeto provides flexible and scalable solutions that perform well for businesses of all sizes. Whether you’re finding prospects on desktop or mobile, you can grab their attention and win their hearts with an attractive and functional site.</p>
                                                    <br />
                                                    <h4>Making Your Vision a Reality</h4>
                                                    <p>Here at Leeto, we stand for excellence. We go the extra mile to create innovative and engaging websites that deliver meaningful results. We have the experience and technical know-how to make your website stand out. Whether you want to revamp your system or build a new one, you can trust our professional web developers to help you every step of the way.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mb-5 text-center">
                                <h2 className="title theme-gradient">Why Choose Leeto?</h2>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12 order-lg-1 order-md-2">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4>Industry Expertise</h4>
                                                    <p>We work with businesses of all sizes. Whether you have just one or a million site visitors each day, we deploy high-performing and scalable websites that give users a superior experience.</p>
                                                    <br />
                                                    <br/>
                                                    <h4>Flexibility</h4>
                                                    <p>We have a team of seasoned web developers who take pride in using out-of-the-box end-to-end solutions to build high-impact experiences.</p>
                                                    <br />
                                                    <h4>Collaborative Partnership</h4>
                                                    <p>From strategy to implementation, we will collaborate closely with you to ensure that we fully understand and execute your design and branding preferences, as well as your business goals.</p>
                                                    <br />
                                                    <h4>Cutting-Edge Technology</h4>
                                                    <p>At Leeto, we use custom solutions to maximise business efficiency. We combine the latest technologies with our end-to-end strategies to develop websites that deliver real impact and fuel continuous growth.</p>
                                                    <br />
                                                    <h4>Seamless Integration</h4>
                                                    <p>Our web development team can integrate all your systems seamlessly to empower your business, helping you overcome unprecedented challenges and sustain forward momentum.</p>
                                                    <br />
                                                    <h4>Advanced Security and Thorough Testing</h4>
                                                    <p>Every website we work on undergoes extensive performance, functionality and usability testing. These rigorous tests ensure that our websites are optimised for end users, free from bugs and safe against any kind of malware.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-lg-2 order-md-1">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/technology.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default WebDevelopment;