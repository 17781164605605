import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import TagManager from 'react-gtm-module';


class Erp extends Component{

    render(){
        const tagManagerArgs = {
            gtmId: 'G-NSK5PESMEF'
        };
        TagManager.initialize(tagManagerArgs)
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='ERP Integrations & Consulting' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image-web-development"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h1 className="title theme-gradient">Optimising Daily Operations with Custom ERP Services</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <p>Build a long-term infrastructure with ERP solutions that scale with your growing business. At Leeto, we empower businesses of all shapes and sizes with tailor-made solutions that enable them to be more efficient and adaptable in this ever-changing landscape of business.</p>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2>ERP Consulting Services</h2>
                                                    <p>We always go the extra mile to comprehend your business processes and requirements. Our highly experienced technical consultants can help you make informed decisions on customisation and ERP choice so you can get the most out of your business systems.</p>
                                                    <p>Once we fully understand your needs, we prepare an extensive roadmap on the project’s implementation. Our developers can then build a custom ERP system that meets the needs of your business, overcomes pain points and provides a channel for constant communications.</p>
                                                    <br />
                                                    <br/>
                                                    <h2>Advantages of a Custom ERP System</h2>
                                                    <ul>
                                                        <li>
                                                            <b>Eliminate manual tasks</b>
                                                            <br/>
                                                            <span>
                                                                Improve overall efficiency in the workplace by automating mundane and repetitive tasks. With a custom ERP solution, your employees can focus on value-added tasks, streamline processes and collect relevant data.
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>Streamline data</b>
                                                            <br/>
                                                            <span>
                                                                Encourage effective cross-departmental collaboration by integrating and streamlining data across the company into a single system. 
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>Generate accurate reports</b>
                                                            <br/>
                                                            <span>
                                                                Empower decision-makers in your business to make important decisions more efficiently by giving them access to extensive and reliable reports.
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>Improve data security</b>
                                                            <br/>
                                                            <span>
                                                                Protect important company and customer data by granting different access rights to different users. Using a custom ERP, you can prevent data leakage and improve accuracy. 
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>Enhance customer relationships</b>
                                                            <br/>
                                                            <span>
                                                                Increase customer satisfaction and build personalised brand experiences with an ERP system that gives sales and customer service representatives easy access to crucial customer data.
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>Comply with regulations</b>
                                                            <br/>
                                                            <span>
                                                                Using an ERP software solution removes the headaches that come with regulatory compliance as we can develop systems with compliance built in. 
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                    </ul>
                                                    <h2>Why Choose Leeto?</h2>
                                                    <p>
                                                        Leeto is full of innovative, highly experienced ERP developers, technical consultants and project managers who are also well versed in business. We take a holistic approach to implementing ERP solutions, which allows us to optimise your processes and confirm that everything is working the way they should.
                                                         <br/>
                                                         <br/>
                                                        We are with you every step of the way – even in preparing your employees for change. We offer in-house technical support and training to make sure you get the most value out of your ERP.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default Erp;