import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactForm from "../elements/contact/Contact";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 bg_image-web-development"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h1 className="title theme-gradient">Contact Us</h1>
                                    <p> Get in touch woth our team and start making your business better right now. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact With Phone Number</h4>
                                        <p><a href="tel:+355 69 72 67 151">+355 69 72 67 151</a></p>
                                        <p><a href="tel:+355 68 43 97 695">+355 68 43 97 695</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href="mailto:contact@leetodigitalagency.com">contact@leetodigitalagency.com</a></p>
                                        <p><a href="mailto:leeto13370@gmail.com">leeto13370@gmail.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>Abdyl Frasheri Street <br /> Nr 10, App 15</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactForm />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="position-relative">
                    <a target="_blank" rel="noopener noreferrer" href="https://g.page/leeto-digital-agency?share" title="Leeto Digital Agency On Google Maps">
                    <img
                        sizes="(max-width: 1400px) 100vw, 1400px"
                        srcSet="
                        /assets/images/map/leeto-map_xsoafo_c_scale,w_320.webp 320w,
                        /assets/images/map/leeto-map_xsoafo_c_scale,w_550.webp 550w,
                        /assets/images/map/leeto-map_xsoafo_c_scale,w_734.webp 734w,
                        /assets/images/map/leeto-map_xsoafo_c_scale,w_879.webp 879w,
                        /assets/images/map/leeto-map_xsoafo_c_scale,w_1016.webp 1016w,
                        /assets/images/map/leeto-map_xsoafo_c_scale,w_1149.webp 1149w,
                        /assets/images/map/leeto-map_xsoafo_c_scale,w_1400.webp 1400w"
                        src="/assets/images/map/leeto-map_xsoafo_c_scale,w_1400.webp"
                        alt="Leeto Digital Agency On Google Maps"
                        style={{objectFit: 'cover', height: '100%', width: '100%'}} />
                    </a>
                </div>
                {/* End Contact Map  */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </React.Fragment>
        )
    }
}
export default Contact