import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'CREATIVITY',
        description: 'We bring our diverse background of design and strategic planning to work for you. Your materials will not only look great, they will get results.'
    },
    {
        icon: <FiLayers />,
        title: 'QUALITY',
        description: 'We have a rigorous quality check process in place to make sure whatever we deliver is up to the expectations of our clients.'
    },
    {
        icon: <FiUsers />,
        title: 'INNOVATIVE',
        description: 'Our strong passion for innovation and creativity makes us a brilliant digital marketing and web development partner.'
    },
    {
        icon: <FiUsers />,
        title: 'PRICE',
        description: 'Our prices are competitive and fair. Any unexpected or additional expenses must be pre-approved by you.'
    },
    {
        icon: <FiUsers />,
        title: 'CERTIFIED',
        description: 'Our Google Certified team will make sure you get professional services for your business.'
    },
    {
        icon: <FiUsers />,
        title: 'THE BOSS',
        description: 'We will give you our recommendations, but ultimately, every decision about your website is yours.'
    },
]
class ChooseUs extends Component{
    render(){
        let title = 'Why Choose US',
        description = '';
        return(
            <React.Fragment>
                <div className="container-fluid choose-us-container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 ">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title">
                                        <h2>{title}</h2>
                                        <p>{description}</p>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="row">
                                        {ServiceList.map( (val , i) => (
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-4" key={i}>
                                                {/* <a href="/service-details"> */}
                                                    <div className="choose-us choose-us-leeto">
                                                        <div className="icon">
                                                            <svg stroke="currentColor" viewBox='0 0 120 100' width="70" height="70">
                                                                <path d='M38,2 
                                                                        L82,2 
                                                                        A12,12 0 0,1 94,10 
                                                                        L112,44 
                                                                        A12,12 0 0,1 112,56
                                                                        L94,90       
                                                                        A12,12 0 0,1 82,98
                                                                        L38,98
                                                                        A12,12 0 0,1 26,90
                                                                        L8,56
                                                                        A12,12 0 0,1 8,44
                                                                        L26,10
                                                                        A12,12 0 0,1 38,2' 
                                                                        fill="none" />
                                                            </svg>
                                                        </div>
                                                        <div className="content">
                                                            <h3 className="title">{val.title}</h3>
                                                            <p>{val.description}</p>
                                                        </div>
                                                    </div>
                                                {/* </a> */}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-lg-5 align-self-center d-none d-sm-none d-md-none d-lg-block">
                                    <img src={`/assets/images/4.png`} alt="Portfolio Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ChooseUs;