import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiSearch, FiTrendingUp } from "react-icons/fi";
import { Link } from 'react-router-dom';

const ServiceList = [
    {
        icon: <FiCast />,
        url: '/e-commerce',
        title: 'E-commerce',
        description: 'Provide your customers a seamless user experience through engaging e-commerce websites built on the best platforms including Magento, PrestaShop, and WooCommerce. Our omni-digital approach ensures your site is easily accessible on mobile apps, desktops, and other devices.'
    },
    {
        icon: <FiLayers />,
        url: '/web-development',
        title: 'Web Design & Development',
        description: 'Build user engagement with rich, interactive websites created by our elite team of developers based on your design specifications.'
    },
    {
        icon: <FiUsers />,
        url: '/crm',
        title: 'CRM Solutions',
        description: 'Place your customers at the forefront of your business with an advanced customer relationship management (CRM) system.'
    },
    {
        icon: <FiUsers />,
        url: '/erp',
        title: 'ERP Solutions',
        description: 'Simplify and automate your workflows for more efficient business operations through integrated ERP systems.'
    },
    {
        icon: <FiSearch />,
        url: '/seo-digital-marketing',
        title: 'Search Engine Optimization',
        description: 'Improve your site’s performance with the best SEO practices. We’ll help you boost your site traffic and conversion rates.'
    },
    {
        icon: <FiTrendingUp />,
        url: '/seo-digital-marketing',
        title: 'Digital Marketing',
        description: 'Utilize the power of digital media. We cover all facets of internet marketing, including popular platforms such as Google, Facebook, and LinkedIn.'
    },
]

class HomepageService extends Component{
    render(){
        let title = 'Services',
        description = 'Check our list of services or request a custom service by contacting us.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <Link to="contact" className="btn-transparent rn-btn-dark" title="Contact Us">
                                    <span className="text">Request Custom Service</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt_md--50 mt-5">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4 mb-lg-0" key={i}>
                                    <Link to={val.url} title={val.title}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default HomepageService;
