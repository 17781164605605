import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";


class Header extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }


    openMenu = (event) => {
        event.target.parentElement.querySelector('.submenu').classList.toggle("active");
        event.target.classList.toggle("open");
    }
    
    render(){
        // var elements = document.querySelectorAll('.has-droupdown > a:not(.custom)');
        // for(var i in elements) {
        //     if(elements.hasOwnProperty(i)) {
        //         elements[i].onclick = function() {
        //             this.parentElement.querySelector('.submenu').classList.toggle("active");
        //             this.classList.toggle("open");
        //         }
        //     }
        // }
        const { logo, color='default-color' } = this.props;
        let logoUrl;
        if(logo === 'light'){
            logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Leeto Digital Agency" />;
        }else if(logo === 'dark'){
            logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Leeto Digital Agency" />;
        }else if(logo === 'symbol-dark'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Leeto Digital Agency" />;
        }else if(logo === 'symbol-light'){
            logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Leeto Digital Agency" />;
        }else{
            logoUrl = <img src="/assets/images/logo/logo.png" alt="Leeto Digital Agency" />;
        }
        
        return(
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <Link to="/">{logoUrl}</Link>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu mr-lg-5">
                                <li><Link to="/">Home</Link></li>
                                <li className="has-droupdown"><Link className="custom" onClick={this.openMenu} to="#" >Services</Link>
                                    <ul className="submenu">
                                        <li><Link title="Web Development" to="/web-development">Web Development</Link></li>
                                        <li><Link title="E-Commerce" to="/e-commerce">E-Commerce</Link></li>
                                        <li><Link title="CRM" to="/crm">CRM</Link></li>
                                        <li><Link title="ERP" to="/erp">ERP</Link></li>
                                        <li><Link title="SEO and Digital Marketing" to="/seo-digital-marketing">SEO and Digital Marketing</Link></li>
                                    </ul>
                                </li>
                                <li><Link to="/contact" >Contact</Link></li>
                            </ul>
                        </nav>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;