import React, { Component } from "react";
import {Helmet} from 'react-helmet'
class PageHelmet extends Component{

    componentDidMount() {

        window.gtag('event', 'page_view', {
            page_title: this.props.pageTitle + " || Best Digital Agency in Tirana - Albania",
            page_location: window.location.href,
            page_path: window.location.pathname,
            send_to: 'G-NSK5PESMEF'
        })
        
        // window.gtag('config', 'G-NSK5PESMEF', {
        //     page_title: this.props.pageTitle + " || Best Digital Agency in Tirana - Albania",
        //     page_path: window.location.pathname + window.location.search
        // })
    }
    
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Best Digital Agency in Tirana - Albania </title>
                    <meta name="description" content="Leeto Digital Agency based in Tirana Albania offers development services such as E-commerce, CRM, ERP, Mobile Apps, Custom Development and many other services. " />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
