import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Crm extends Component{

    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='CRM Development & Consulting'  />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image-web-development"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h1 className="title theme-gradient">Building Custom CRM Solutions</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <p>Leeto develops bespoke CRM systems that empower companies of all shapes and sizes to thrive in an ever-changing business landscape</p>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2>A Trusted Provider of CRM Development and Consulting Services</h2>
                                                    <p>Here at Leeto, we understand that there is no one-size-fits-all formula when it comes to CRM solutions. Every business is unique after all. That is why our highly qualified team of experts is committed to developing custom CRM systems that meet your business goals and needs – on time and within budget.</p>
                                                    <br />
                                                    <br/>
                                                    <h2>CRM Consulting Services from Leeto</h2>
                                                    <p>Our CRM consulting services are designed to aid in customer acquisition, conversion and retention. Whether you run a B2B or B2C company, we won’t let your CRM prevent your business from driving customer revenue and growth.</p>
                                                    <p>We can make enhancements to an existing CRM, enabling it to meet the growing complexities of your business. We can also bring several CRM solutions together. If you want to change or switch to a cloud-based CRM, we can also help you migrate your system.</p>
                                                    <br />
                                                    <br />
                                                    <h2>Here are some of the pains that our bespoke solutions can overcome:</h2>
                                                    <ul>
                                                        <li>Cluttered, inadequate customer details</li>
                                                        <li>Poor performance in sales, marketing and customer services</li>
                                                        <li>Ineffective collaboration between departments</li>
                                                        <li>Low customer satisfaction</li>
                                                        <li>Poor data quality and reporting</li>
                                                    </ul>
                                                    <br />
                                                    <br />
                                                    <h2>Advantages of CRM Systems:</h2>
                                                    <ul>
                                                        <li>
                                                            <b>74% improvement in customer relations</b>
                                                            <br/>
                                                            <br/>
                                                            <span>
                                                                Put customers at the heart of your business. From subscription management to product and service delivery, a custom CRM system enables you to create effective strategies for managing customer-related processes and personalising brand experiences.
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>65% increase in sales quotas</b>
                                                            <br/>
                                                            <br/>
                                                            <span>
                                                                Automating and optimising the sales process gives you greater visibility and higher efficiency, increasing lead conversions and sales rates.
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>50% improvement in productivity</b>
                                                            <br/>
                                                            <br/>
                                                            <span>
                                                                Empower employees with seamless data, efficient cross-departmental collaboration, essential integrations, better reporting, and analysis with a custom CRM and improve productivity in the process.
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                        <li>
                                                            <b>40% decrease in labour costs</b>
                                                            <br/>
                                                            <br />
                                                            <span>
                                                                Implementing a custom CRM that can scale with your business and handle your growing pains enables you to cut costs in various areas of the company.
                                                            </span>
                                                            <br/>
                                                            <br/>
                                                        </li>
                                                    </ul>
                                                    <h2>Why Choose Leeto?</h2>
                                                    <p>
                                                        Made up of highly qualified CRM experts, Leeto equips clients with custom tools that improve marketing, increase sales, and promote business growth. We bring innovation and creativity to the forefront in all that we do. Our software developers go above and beyond to understand the peculiarities of your business, making sure your customised CRM solution meets your requirements, suits your working style and fits your niche.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default Crm;