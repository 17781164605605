import React, { Component } from "react";

class HomePageSlider extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <h1 className="title theme-gradient">DIGITAL AGENCY</h1>
                                <h2 className="title theme-gradient mt-5">DRIVEN BY INNOVATION</h2>
                            </div>
                        </div>
                    </div>
                    {/* Start Service Area */}
                    <div className="service-wrapper service-white mt-5">
                        <h3 className="rn-text-white">
                        Creative ideas, innovative solutions, high-quality services – these are what we strive to deliver to our valued clients to ensure that they gain maximum benefits from their digital marketing investments.
                        </h3>
                    </div>
                    {/* End Service Area */}
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default HomePageSlider;