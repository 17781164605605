import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class SeoDigitalMarketing extends Component{

    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='SEO and Digital Marketing' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image-web-development"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h1 className="title theme-gradient">SEO and Digital Marketing</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-5">
                                <h2>Lead the Online Market</h2>
                                <p>
                                    Digital marketing remains as competitive as ever, while shifting trends and evolving markets only serve to make things even more complicated. Additionally, with thousands of potential competitors on the internet, it can be extremely difficult to make your presence known, let alone stand on the top spot. 
                                     <br/>
                                     <br/>
                                    This is where we come in. 
                                     <br/>
                                     <br/>
                                    Leeto’s highly skilled ensemble of Google-certified experts will bring your brand to your audience’s doorstep before the rest of the competition can through sound SEO techniques and intricate digital marketing strategies.
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h2>Digital Marketing Services</h2>
                                                    <p>Leeto offers a wide variety of digital services to help bolster your brand’s online presence. Our teams of experts are well versed in the art of internet marketing and always eager to help your business flourish. Here’s what we can do:</p>
                                                    <br />
                                                    <br/>
                                                    <h2>Social Media Marketing</h2>
                                                    <p>Knowledge is power, and there’s no better way to get to know your audience than through social media. We create dynamic and engaging content for various platforms to breathe life into your brand. We’ll help you reach out to your audience and create your brand’s own online community.</p>
                                                    <br/>
                                                    <br/>
                                                    <h2>PPC Advertising</h2>
                                                    <p>Increase your website’s traffic and make the most of your ad spending budget through optimised pay-per-click campaigns. We can formulate short-term and long-term PPC strategies that are tailor-made to fit your brand’s needs so that you can make the most efficient use of your money.</p>
                                                    <br/>
                                                    <br/>
                                                    <h2>Sales & Conversion Funnels</h2>
                                                    <p>Maximise your profit through clear and concise sales funnels that can convert any curious website visitor into a loyal customer with the help of our expert web designers and copywriters. </p>
                                                    <br/>
                                                    <br/>
                                                    <h2>Web Design & Development</h2>
                                                    <p>Nobody likes a cluttered website interface. We can overhaul your site to give it that streamlined, modern and professional look that combines sleek aesthetics with uncompromised clarity and ease of navigation.  </p>
                                                    <br/>
                                                    <br/>
                                                    <h2>Search Engine Optimisation</h2>
                                                    <p>
                                                        SEO is at the core of all online marketing efforts. After all, a stunning website won’t matter if no one can see it. <br/>
                                                        A properly optimised website will rank higher in search engine results pages, making it more visible to the people who matter most. <br/>
                                                        Leeto will provide your website with thorough content optimisation through the use of high-ranking keywords and laser-accurate targeting to make sure your audience will come knocking at your door instead of the other way around.
                                                    </p>
                                                    <br/>
                                                    <br/>
                                                    <h2>Why Choose Leeto?</h2>
                                                    <p>
                                                        Leeto is full of innovative, highly experienced ERP developers, technical consultants and project managers who are also well versed in business. We take a holistic approach to implementing ERP solutions, which allows us to optimise your processes and confirm that everything is working the way they should.
                                                         <br/>
                                                         <br/>
                                                        We are with you every step of the way – even in preparing your employees for change. We offer in-house technical support and training to make sure you get the most value out of your ERP.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default SeoDigitalMarketing;