import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import HomePageSlider from "../component/slider/HomePageSlider";
import HomepageService from "../elements/service/HomepageService";
import ChooseUs from "../component/HomeLayout/homepage/ChooseUs";
import Helmet from "../component/common/Helmet";
class HomePage extends Component{
    render(){
        return(
            <div className="active-dark">
                <Helmet pageTitle="Leeto Digital Agency Tirana" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <HomePageSlider />
                </div>
                {/* End Slider Area   */}

                <div className="service-area ptb--80  bg_image bg_image--3">
                   <div className="container">
                        <HomepageService />
                   </div>
                </div>

                {/* Start Choose Us Area */}
                <div className="choose-us-area ptb--60 bg_color--1">
                    <div className="choose-us-sacousel-inner mb--55">
                        <ChooseUs />
                    </div>
                </div>
                {/* End Choose Us Area */}

                {/* Start Contact Map  */}
                <div className="position-relative">
                    <a target="_blank" rel="noopener noreferrer" href="https://g.page/leeto-digital-agency?share" title="Leeto Digital Agency On Google Maps">
                        <img
                            sizes="(max-width: 1400px) 100vw, 1400px"
                            srcSet="
                            /assets/images/map/leeto-map_xsoafo_c_scale,w_320.webp 320w,
                            /assets/images/map/leeto-map_xsoafo_c_scale,w_550.webp 550w,
                            /assets/images/map/leeto-map_xsoafo_c_scale,w_734.webp 734w,
                            /assets/images/map/leeto-map_xsoafo_c_scale,w_879.webp 879w,
                            /assets/images/map/leeto-map_xsoafo_c_scale,w_1016.webp 1016w,
                            /assets/images/map/leeto-map_xsoafo_c_scale,w_1149.webp 1149w,
                            /assets/images/map/leeto-map_xsoafo_c_scale,w_1400.webp 1400w"
                            src="/assets/images/map/leeto-map_xsoafo_c_scale,w_1400.webp"
                            alt="Leeto Digital Agency On Google Maps"
                            style={{objectFit: 'cover', height: '100%', width: '100%'}} />
                    </a>
                </div>
                {/* End Contact Map  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
            </div>
        )
    }
}
export default HomePage;